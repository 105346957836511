import React from 'react';
import { Redirect } from 'react-router-dom';

/**
 *
 * Authentication
 *
 */
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';

/**
 *
 * Dashboard
 *
 */
import Dashboard from '../pages/Dashboard/Dashboard';
import Api from '../pages/Dashboard/Api';
import AddNewMembership from '../pages/Dashboard/ShopMembership/AddNewMembership';
import GetAllMembership from '../pages/Dashboard/ShopMembership/GetAllMembership';

import AddNewContact from '../pages/Dashboard/Contact/AddNewContact';
import GetAllContacts from '../pages/Dashboard/Contact/GetAllContacts';
import UpdateContact from '../pages/Dashboard/Contact/UpdateContact';
import GetAContactDetail from '../pages/Dashboard/Contact/GetAContactDetail';
import CreateNewOrder from '../pages/Dashboard/Order/CreateNewOrder';
import GetMembershipDetail from '../pages/Dashboard/ShopMembership/GetMembershipDetail';
import ActivateMembership from '../pages/Dashboard/ShopMembership/ActivateMembership';
import GetValidityLogs from '../pages/Dashboard/ShopMembership/GetValidityLogs';
import ExtendValidity from '../pages/Dashboard/ShopMembership/ExtendValidity';
import GetPendingValidity from '../pages/Dashboard/ShopMembership/GetPendingValidity';
import AvailablePlans from '../pages/Dashboard/ShopMembership/AvailablePlans';
import GetAllOrders from '../pages/Dashboard/Order/GetAllOrders';
import GetOrderDetail from '../pages/Dashboard/Order/GetOrderDetail';
import GetEstimateDelivery from '../pages/Dashboard/Order/GetEstimatedDelivery';
import GetAllMembershipPlans from '../pages/Dashboard/Membership/GetAllMembershipPlans';
import GetAllValidityPlans from '../pages/Dashboard/Membership/GetAllValidityPlans';

const authProtectedRoutes = [


  // Dashboard
  { path: '/dashboard', component: () => <Redirect to="dashboard/membership/new" /> },
  { path: '/dashboard/membership', component: () => <Redirect to="/dashboard/membership/new" /> },
  { path: '/dashboard/membership/new', component: AddNewMembership },
  { path: '/dashboard/membership/all', component: GetAllMembership },
  { path: '/dashboard/membership/detail', component: GetMembershipDetail },
  { path: '/dashboard/membership/activate', component: ActivateMembership },
  { path: '/dashboard/membership/logs', component: GetValidityLogs },
  { path: '/dashboard/membership/validity', component: GetPendingValidity },
  { path: '/dashboard/membership/extend', component: ExtendValidity },
  { path: '/dashboard/membership/membership_plans', component: GetAllMembershipPlans },
  { path: '/dashboard/membership/validity_plans', component: GetAllValidityPlans },
  { path: '/dashboard/contacts/new', component: AddNewContact },
  { path: '/dashboard/contacts/all', component: GetAllContacts },
  { path: '/dashboard/contacts/update', component: UpdateContact },
  { path: '/dashboard/contacts/contact', component: GetAContactDetail },
  { path: '/dashboard/orders/new', component: CreateNewOrder },
  { path: '/dashboard/orders/all', component: GetAllOrders },
  { path: '/dashboard/orders/order', component: GetOrderDetail },
  // { path: '/dashboard/ordders/delivery', component: GetEstimateDelivery },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
  
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login }
];

export { authProtectedRoutes, publicRoutes };

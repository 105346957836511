import { combineReducers } from 'redux';

/**
 *
 * Reducers
 *
 */
import Layout from './layout/reducer';
import Login from './auth/reducer';
import NotificationHistory from './notification/reducer';

const rootReducer = combineReducers({
	Layout,
	Login,
	notification_state: NotificationHistory,
});

export default rootReducer;

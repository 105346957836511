import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import SidebarContent from './SidebarContent';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="vertical-menu"
          style={{ position: 'fixed', top: '70px' }}
        >
          <div data-simplebar className="h-100">
            {this.props.type !== 'default' ? (
              <SimpleBar style={{ maxHeight: '100%' }}>
                <SidebarContent />
              </SimpleBar>
            ) : (
              <SidebarContent />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout
  };
};
export default connect(mapStatetoProps)(withRouter(Sidebar));

import {
	FETCH_INITIAL_HISTORY,
	INITIAL_HISTORY_LOAD_SUCCESSFUL,
	FETCH_NEXT_NOTIFICATION_PAGE,
	REMOVE_NOTIFICATION_FROM_HISTORY,
	REMOVE_RED_DOT_BELL_ICON,
	GOT_NEW_NOTIFICATION_HISTORY,
} from './actionTypes';

export const fetchInitialNotificationHistory = () => {
	return {
		type: FETCH_INITIAL_HISTORY,
	};
};

export const fetchNextNotifications = (required_page) => {
	return {
		type: FETCH_NEXT_NOTIFICATION_PAGE,
		payload: { required_page: required_page },
	};
};

export const initialDataLoadSuccessful = (payload) => {
	return {
		type: INITIAL_HISTORY_LOAD_SUCCESSFUL,
		payload: payload,
	};
};

export const removeNotificationFromHistory = (notification_id) => {
	return {
		type: REMOVE_NOTIFICATION_FROM_HISTORY,
		payload: { notification_id: notification_id },
	};
};

export const removeRedDotFromBellIcon = () => {
	return {
		type: REMOVE_RED_DOT_BELL_ICON,
	};
};

export const addNewNotificationHistoryToTheList = (payload) => {
	return {
		type: GOT_NEW_NOTIFICATION_HISTORY,
		payload: payload,
	};
};

export const initialDataLoadFailed = (payload) => {};

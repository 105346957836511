import React, { useState } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  Badge
} from 'reactstrap';
import PostRequestComponent from '../../components/Common/PostRequest';
const Api = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);

  const handleSendRequest = async () => {
    try {
      const response = await axios.post(url, JSON.parse(requestBody));
      setResponse(response.data);
    } catch (error) {
      setResponse(error.message);
    }
  };
  const headers=[
    {
      title:'API KEY:',
      type:'text'
    }
  ]
  const body=[
    {
      title:'Membership Code:',
      type:'text'
    },
    {
      title:'Membership Name:',
      type:'text'
    },
    {
      title:'Payment Amount:',
      type:'text'
    },
    {
      title:'Payment Method:',
      type:'text'
    },
    {
      title:'Payment Note:',
      type:'text'
    },
    {
      title:'Payment Reciept:',
      type:'file'
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
      <PostRequestComponent title="Membership" headers={headers} body={body} url="http://localhost:5050/api/v2/tp/membership"/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default Api;

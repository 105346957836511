import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
	FETCH_INITIAL_HISTORY,
	FETCH_NEXT_NOTIFICATION_PAGE,
	REMOVE_NOTIFICATION_FROM_HISTORY,
	REMOVE_RED_DOT_BELL_ICON,
} from './actionTypes';
import { initialDataLoadSuccessful } from './actions';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';

/**
 *
 * Fetch request
 *
 */
async function fetchData(required_page = 1, required_limit = 10) {
	try {
		const admin_id = JSON.parse(localStorage.getItem('user_details'))?.user_id;
		const city_id = JSON.parse(localStorage.getItem('city_details'))?._id;

		setAuthToken();
		const { data: notification_data } = await sendRequest.get(
			`v2/${city_id}/admin/${admin_id}/notification/history/list?page=${required_page}&limit=${required_limit}`
		);
		return notification_data;
	} catch (e) {
		console.log(e);
	}
}

async function deleteNotification(notification_id) {
	try {
		const admin_id = JSON.parse(localStorage.getItem('user_details'))?.user_id;
		const city_id = JSON.parse(localStorage.getItem('city_details'))?._id;

		setAuthToken();
		await sendRequest.delete(
			`v2/${city_id}/admin/${admin_id}/notification/history/remove/${notification_id}`
		);
	} catch (e) {
		console.log(e);
	}
}

async function sendReadNotificationRequest() {
	try {
		const admin_id = JSON.parse(localStorage.getItem('user_details'))?.user_id;
		const city_id = JSON.parse(localStorage.getItem('city_details'))?._id;

		setAuthToken();
		await sendRequest.put(`v2/${city_id}/admin/${admin_id}/notification/history/list/read`);
	} catch (e) {
		console.log(e);
	}
}

function* fetchInitialNotifications() {
	try {
		const notification_data = yield call(fetchData);
		yield put(initialDataLoadSuccessful(notification_data));
	} catch (e) {}
}

function* fetchNextNotificationItems({ payload: { required_page } }) {
	try {
		const notification_data = yield call(fetchData, required_page);
		yield put(initialDataLoadSuccessful(notification_data));
	} catch (e) {}
}

function* removeNotificationFromDatabase({ payload: { notification_id } }) {
	try {
		yield call(deleteNotification, notification_id);
	} catch (e) {}
}

function* readNotificationHistoryList() {
	try {
		yield call(sendReadNotificationRequest);
	} catch (e) {}
}

export function* watchFetchInitialNotifications() {
	yield takeEvery(FETCH_INITIAL_HISTORY, fetchInitialNotifications);
}

export function* watchFetchNextNotificationItems() {
	yield takeEvery(FETCH_NEXT_NOTIFICATION_PAGE, fetchNextNotificationItems);
}

export function* watchRemoveNotificationFromDatabase() {
	yield takeEvery(REMOVE_NOTIFICATION_FROM_HISTORY, removeNotificationFromDatabase);
}

export function* watchReadNotificationHistoryList() {
	yield takeEvery(REMOVE_RED_DOT_BELL_ICON, readNotificationHistoryList);
}

function* notificationSaga() {
	yield all([
		fork(watchFetchInitialNotifications),
		fork(watchFetchNextNotificationItems),
		fork(watchRemoveNotificationFromDatabase),
		fork(watchReadNotificationHistoryList),
	]);
}

export default notificationSaga;

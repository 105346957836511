import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import GetRequestComponent from '../../../components/Common/GetRequest';
const GetAllValidityPlans = () => {
    const [url, setUrl] = useState('');
    const [requestBody, setRequestBody] = useState('');
    const [response, setResponse] = useState(null);
    const [breadcrumbItems] = useState([
      { title: 'Membership', link: '#' },
      { title: 'Available Validity Plans', link: '#' }
    ]);
    const headers=[
      {
        title:'apikey',
        type:'text',
        required:true,
      }
    ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Get Available Validity Plans"
            breadcrumbItems={breadcrumbItems}
          />
          <p>Retrieves a list of available Validity plans. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <GetRequestComponent title="Get All Membership Plans" headers={headers} url='/v2/tp/membership/plans/validity_pack'/>
      </Container>
      </div>
    </React.Fragment>
  )
}

export default GetAllValidityPlans
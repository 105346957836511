import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import GetRequestComponent from '../../../components/Common/GetRequest';
const GetPendingValidity = () => {
    const [url, setUrl] = useState('');
    const [requestBody, setRequestBody] = useState('');
    const [response, setResponse] = useState(null);
    const [breadcrumbItems] = useState([
      { title: 'Membership', link: '#' },
      { title: 'Get Pending Validity', link: '#' }
    ]);
    const headers=[
      {
        title:'apikey',
        type:'text',
        required:true,
      }
    ]
    const queryparams=[
      {
        title:'page',
        type:'text',
      },
      {
        title:'limit',
        type:'text',
      }
    ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Get Pending Validity List"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Get all Pending(Not Approved) Validity Plans of the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <GetRequestComponent title="Get Pending Validity List" headers={headers} queryparams={queryparams} url='/v2/tp/membership/pending/validity/list'/>
      </Container>
      </div>
    </React.Fragment>
  )
}

export default GetPendingValidity;
import React, { Component } from 'react';
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader
} from '../../store/actions';
import { showOrderAcceptNotification } from '../../helpers';

function BadgeSpan({ count }) {
  return count && count !== 0 ? (
    <span className="ml-2 text-light bg-primary px-1 rounded">{count}</span>
  ) : null;
}

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');

    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title" style={{ color: '#fff' }}>
              Menu
            </li>
            <li>
              <Link to="/dashoard/membership/new" className="has-arrow waves-effect">
                <i className="ri-store-2-line"></i>
                <span className="ml-1">Membership</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard/membership/membership_plans">Get All Membership Plans</Link>
                </li>
                <li>
                  <Link to="/dashboard/membership/validity_plans">Get All Validity Plans</Link>
                </li>
                </ul>
                </li>
            <li>
              <Link to="/dashoard/membership/new" className="has-arrow waves-effect">
                <i className="ri-store-2-line"></i>
                <span className="ml-1">Shop Membership</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard/membership/new">Add New Membership</Link>
                </li>
                <li>
                  <Link to="/dashboard/membership/all">Get All Memberships</Link>
                </li>
                <li>
                  <Link to="/dashboard/membership/detail">Get A Membership Detail</Link>
                </li>
                <li>
                  <Link to="/dashboard/membership/activate">Activate Membership Plan</Link>
                </li>
                <li>
                  <Link to="/dashboard/membership/logs">Get Validity Logs</Link>
                </li>
                <li>
                  <Link to="/dashboard/membership/validity">Get Pending Validity List</Link>
                </li>
                <li>
                  <Link to="/dashboard/membership/extend">Extend Membership Validity</Link>
                </li>
                </ul>
                </li>
            <li>
              <Link to="/dashoard/contacts/new" className="has-arrow waves-effect">
                <i className=" ri-e-bike-line"></i>
                <span className="ml-1">Contacts</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard/contacts/new">Add New Contact</Link>
                </li>
                <li>
                  <Link to="/dashboard/contacts/all">Get All Contacts</Link>
                </li>
                <li>
                  <Link to="/dashboard/contacts/update">Update A Contact</Link>
                </li>
                <li>
                  <Link to="/dashboard/contacts/contact">Get A Contact Detail</Link>
                </li>
                </ul>
                </li>
            <li>
              <Link to="/dashoard/orders/new" className="has-arrow waves-effect">
                <i className="ri-luggage-cart-line"></i>
                <span className="ml-1">Orders</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/dashboard/orders/new">Create New Order</Link>
                </li>
                <li>
                  <Link to="/dashboard/orders/all">Get All Orders</Link>
                </li>
                <li>
                  <Link to="/dashboard/orders/order">Get A Order Detail</Link>
                </li>
                {/* <li>
                  <Link to="/dashboard/orders/delivery">Get Estimated Delivery Cost</Link>
                </li> */}
                </ul>
                
                </li>
                
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Layout,
    myLoginState: state.Login,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
  })(SidebarContent)
);

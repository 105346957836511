import sendRequest, { setAuthToken } from './axios-setup';

// Login Method
export const loginRequest = (url, data) => {
	return sendRequest
		.post(url, data)
		.then((response) => {
			if (response.status === 400 || response.status === 500) throw response.data;
			return response.data;
		})
		.catch((err) => {
			throw err.response && err.response.data ? err.response.data : err;
		});
};

export const getAuthenticatedUser = (url) => {
	setAuthToken();
	return sendRequest
		.get(url)
		.then((response) => {
			if (response.status === 400 || response.status === 500) throw response.data;
			return response.data;
		})
		.catch((err) => {
			throw err.response && err.response.data ? err.response.data : err;
		});
};

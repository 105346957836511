import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import GetRequestComponent from '../../../components/Common/GetRequest';
const GetAllOrders = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Order', link: '#' },
    { title: 'Get All Orders', link: '#' }
  ]);
  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const queryparams=[
    {
      title:'start_date',
      type:'text',
      placeholder:'YYYY-MM-DD'
    },
    {
      title:'end_date',
      type:'text',
      placeholder:'YYYY-MM-DD'
    },
    {
      title:'page',
      type:'text',
    },
    {
      title:'limit',
      type:'text',
    },
    {
      title:'order_filter',
      type:'dropdown',
      options:[{label:'scheduled and active',value:'scheduled$active'},{label:'active',value:'active'},{label:'cancelled',value:'cancelled'},{label:'delivered',value:'delivered'}]
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Get All Orders"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Get all Orders of the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <GetRequestComponent title="Get All Orders" headers={headers} queryparams={queryparams} url='/v2/tp/order'/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default GetAllOrders;

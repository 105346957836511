import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import PostRequestComponent from '../../../components/Common/PostRequest';
import GetRequestComponent from '../../../components/Common/GetRequest';
const GetOrderDetail = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Order', link: '#' },
    { title: 'Get Order Details', link: '#' }
  ]);
  const handleSendRequest = async () => {
    try {
      const response = await axios.post(url, JSON.parse(requestBody));
      setResponse(response.data);
    } catch (error) {
      setResponse(error.message);
    }
  };
  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const urlparams=[
    {
      title:'orderid',
      type:'text',
      required:true
    }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Get Order Details"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Get A Order Detail Placed by the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <GetRequestComponent title="Get Order Details" headers={headers} urlparams={urlparams} url='/v2/tp/order/:orderid/view' type='form'/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default GetOrderDetail;

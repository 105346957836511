import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
  Dropdown
} from 'reactstrap';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import requestToCurl from 'request-to-curl';
import { fetchToCurl } from 'fetch-to-curl';

const GetRequestComponent = ({headers,urlparams,queryparams,title,url}) => {
//   const [url, setUrl] = useState('');
  const [requesturl, setRequesturl] = useState('');
  const [response, setResponse] = useState("send a Request to see the response.");
  const [status, setStatus] = useState(null);
  const [statusText, setStatusText] = useState(null);
  const [header, setheader] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [param, setparam] = useState({});
  const [query, setquery] = useState({});
  const [curl, setcurl] = useState(`curl 'https://api.sandbox.staging.kd-solutions.in/api${url}' -H \"apikey: test_api_key\"`);
  function appendQueryParams(url, queryParams) {
    if (!queryParams || Object.keys(queryParams).length === 0) {
      return url;
    }
  
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  
    // Check if the URL already contains a query string
    const separator = '?';
  
    return `${url}${separator}${queryString}`;
  }
  const handleSendRequest = async (u) => {
    console.log(u);

    // Check if u is process.env.backend
    if (requesturl === process.env.REACT_APP_BACKEND_BASE_URL) {
      setShowModal(true);
    } else {
      await sendRequest(requesturl);
    }
  };
  const sendRequest = async (u) => {
    console.log(u);
    try {
      // urlparams.map((item)=>{
      //   url.replace(":"+item.title,param[item.title]);
      // });
      let nurl=`${url}`.replace(/:([^/]+)/g, (_, paramName) => param[paramName] || '');
      nurl=appendQueryParams(nurl,query);
      console.log(nurl);
      const response = await axios.get(`${requesturl}${nurl}`, {
        headers: {
          ...header
        }
      });
      // const curlCommand = requestToCurl(axios.defaults, axios.get(`${requesturl}${nurl}`, {...header}));
      setcurl(fetchToCurl(`${requesturl}${nurl}`, {headers: {
        ...header
      }}));
      setResponse(response.data);
      setStatus(response.status);
      setStatusText(response.statusText);
    } catch (error) {
      setResponse(error.response?.data);
      setStatus(error.response.status);
      setStatusText(error.response.statusText);
    }
  };
  const handleModalConfirm = async () => {
    setShowModal(false);
    await sendRequest(process.env.REACT_APP_BACKEND_BASE_URL);
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };
  const getTextColor = () => {
    if (status >= 200 && status < 300) {
      return 'green'; // Success, set text color to green
    } else if (status >= 400 && status < 500) {
      return 'red'; // Client error, set text color to red
    } else if (status >= 500) {
      return 'orange'; // Server error, set text color to orange
    } else {
      return 'black'; // Default color
    }
  };
  let apioptions=[{
    label:'Staging: '+useSelector((state)=>state.Login?.user_details?.staging_apikey),
    value: useSelector((state)=>state.Login?.user_details?.staging_apikey)
  }];
  const production_apikey=useSelector((state)=>state.Login?.user_details?.production_apikey);
  if(process.env.REACT_APP_NODE_ENV==='production'){
    apioptions.push({
      label:'Production: '+production_apikey,
      value: production_apikey
    })
  }
  return (
    <React.Fragment>
          
          <div className='request-content'>
          {showModal && (
        <SweetAlert
          title="Are you sure you want to send the Production request?"
          onConfirm={handleModalConfirm}
          onCancel={handleModalCancel}
          showConfirm={false}
          showCancel={false}
        >
          <div style={{"display": "flex","justifyContent": "space-between","marginTop": "5%"}}>
            <Button variant="success" onClick={handleModalConfirm} style={{"width": "40%","backgroundColor": "green"}}>
              Yes
            </Button>
            <Button variant="danger" onClick={handleModalCancel} style={{"width": "40%","backgroundColor": "red"}}>
              No
            </Button>
          </div>
        </SweetAlert>
      )}
          <div className='request'>
            <Input
              name="posturl"
              type="text"
              value={`${requesturl}${url}`}
              className="form-control"
              readOnly
              style={{ width: '70%' }}
            />
            <Button className="btn btn-info" onClick={()=>handleSendRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}`)} style={{width:"30%"}}>Send Request</Button>
          </div>

          {queryparams&&queryparams.length>0&&<div className='postheader'>
            <h5 class="sc-dAlyuH hNlDMA">Query Params</h5>
            <table class="sc-dAbbOL krDyRf">
              <tbody>
                {queryparams.map((item)=><tr class="last ">
                  <td
                    class="firsttd"
                    kind="field"
                    title={item.title}
                  >
                    <span class="sc-gFqAkR Aouot"></span>
                    <span class="property-name">{item.title}<span style={{color:"red"}}>{item.required==true?'*':''}</span> :</span>
                  </td>
                  <td class="sc-fPXMVe iMGXTw">
                    <div>
                      <div>
                      {item.type!='dropdown'?<Input type={item.type} placeholder={item.placeholder} onChange={(e) =>
                            setquery({ ...query, [item.title]: e.target.value })
                          }></Input>:<Select
                        options={item.options}
                        onChange={(e) =>{
                          setquery({
                            ...query,
                            [item.title]: e.value
                          })
                        }}
                        value={query[item.title]?item.options.find((option) => option.value === query[item.title]):''}
                        isClearable={true}
                        menuPosition="fixed"
                      />}
                      </div>
                    </div>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>}
          {urlparams&&urlparams.length>0&&<div className='postheader'>
            <h5 class="sc-dAlyuH hNlDMA">URL Params</h5>
            <table class="sc-dAbbOL krDyRf">
              <tbody>
                {urlparams.map((item)=><tr class="last ">
                  <td
                    class="firsttd"
                    kind="field"
                    title={item.title}
                  >
                    <span class="sc-gFqAkR Aouot"></span>
                    <span class="property-name">{item.title}<span style={{color:"red"}}>{item.required==true?'*':''}</span> :</span>
                  </td>
                  <td class="sc-fPXMVe iMGXTw">
                    <div>
                      <div>
                      {item.type!='dropdown'?<Input type={item.type} placeholder={item.placeholder} onChange={(e) =>
                            setparam({ ...param, [item.title]: e.target.value })
                          }></Input>:<Select
                        options={item.options}
                        onChange={(e) =>{
                          setparam({
                            ...param,
                            [item.title]: e.value
                          })
                        }}
                        value={param[item.title]?item.options.find((option) => option.value === param[item.title]):''}
                        isClearable={true}
                        menuPosition="fixed"
                      />}
                      </div>
                    </div>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>}
          {headers&&headers.length>0&&<div className='postheader' style={{overflow:"visible"}}>
            <h5 class="sc-dAlyuH hNlDMA">Header Parameters</h5>
            <table class="sc-dAbbOL krDyRf">
              <tbody>
                {headers.map((item)=><tr class="last ">
                  <td
                    class="firsttd"
                    kind="field"
                    title={item.title}
                  >
                    <span class="sc-gFqAkR Aouot"></span>
                    <span class="property-name">{item.title}<span style={{color:"red"}}>{item.required==true?'*':''}</span>:</span>
                  </td>
                  <td class="sc-fPXMVe iMGXTw">
                    <div>
                      <div>
                       {item.title!='apikey'?(item.type!='drowndown'?<Input  type={item.type} placeholder={item.placeholder} onChange={(e) =>
                            setheader({
                              ...header,
                              [item.title]: e.target.value
                            })
                          }></Input>:<Select
                          options={item.options}
                          onChange={(e) =>{
                            setheader({
                              ...header,
                              [item.title]: e.value
                            })
                          }}
                          value={ header[item.title]}
                          isClearable={true}
                          menuPosition="fixed"
                        />):
                          <Select
                            options={apioptions}
                            onChange={(e) =>{
                              setheader({
                                ...header,
                                [item.title]: e.value
                              })
                            setRequesturl(e.label.includes('Staging')==true?process.env.REACT_APP_BACKEND_STAGING_URL:process.env.REACT_APP_BACKEND_BASE_URL)
                            }}
                              value={ header[item.title]?apioptions.find((option) => option.value === header[item.title]):''}
                            isClearable={true}
                            menuPosition="fixed"
                          />}
                      </div>
                    </div>
                  </td>
                </tr>)}
              </tbody>
            </table>
          </div>}
          </div>

      <div className='footer'>
            <div className='request-box'><div style={{display:"flex",justifyContent:"space-between"}}><h2>Request</h2></div>
            <pre id="response" >{JSON.stringify(curl, null, 2)}</pre></div>
            <div className='response-box'><div style={{display:"flex",justifyContent:"space-between"}}><h2>Response</h2><p style={{ color: getTextColor() }}>{status}- {statusText}</p></div>
            <pre id="response" >{JSON.stringify(response, null, 2)}</pre></div>
        </div>
    </React.Fragment>
  );
};

export default GetRequestComponent;

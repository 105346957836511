import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import GetRequestComponent from '../../../components/Common/GetRequest';
const GetValidityLogs = () => {
    const [url, setUrl] = useState('');
    const [requestBody, setRequestBody] = useState('');
    const [response, setResponse] = useState(null);
    const [breadcrumbItems] = useState([
      { title: 'Membership', link: '#' },
      { title: 'Get Validity Logs', link: '#' }
    ]);
    const headers=[
      {
        title:'apikey',
        type:'text',
        required:true,
      }
    ]
    const urlparams=[
      {
        title:'membership_id',
        type:'text',
        required:true
      }
    ]
    const queryparams=[
      {
        title:'page',
        type:'text',
      },
      {
        title:'limit',
        type:'text',
      }
    ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Get Validity Logs"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Get all Validity Plans Purchased for a Membership of the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <GetRequestComponent title="Get Validity Logs" headers={headers} urlparams={urlparams} queryparams={queryparams} url='/v2/tp/membership/:membership_id/validity/logs'/>
      </Container>
      </div>
    </React.Fragment>
  )
}

export default GetValidityLogs;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Media,
  Badge
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  fetchNextNotifications,
  removeNotificationFromHistory,
  removeRedDotFromBellIcon
} from '../../store/actions';

const NotificationDropdown = ({
  notification_state,
  fetchNextNotifications,
  web_notification_sound_mute_status,
  removeNotificationFromHistory,
  removeRedDotFromBellIcon
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (notification_state.red_dot_shown_once) {
      findAndChangeState('block');
    }
    // eslint-disable-next-line
  }, [notification_state.red_dot_shown_once]);

  useEffect(() => {
    if (menuOpen) {
      if (notification_state.red_dot_shown_once) {
        removeRedDotFromBellIcon();
        findAndChangeState('none');
      }
    }

    // eslint-disable-next-line
  }, [menuOpen]);

  const findAndChangeState = (display_style) => {
    document.getElementById('my-notification-dot').style.display =
      display_style;
  };

  const iconMapper = (notification_tag) => {
    switch (notification_tag) {
      case 'kd-shop-register':
        return 'ri-store-2-line';
      case 'kd-order-decline':
        return 'ri-shopping-cart-2-line';
      case 'kd-rider-register':
        return 'ri-file-user-line';
      case 'kd-membership-purchase':
        return 'ri-vip-crown-line';
      case 'kd-rider-balance-capping-exceed':
        return 'ri-wallet-3-line';
      default:
        return 'ri-notification-4-line';
    }
  };

  const removeNotification = (notification_id) => async (e) => {
    e.preventDefault();
    removeNotificationFromHistory(notification_id);
  };

  return (
    <React.Fragment>
      {!web_notification_sound_mute_status && (
        <audio src="/ringtone.mp3" id="my-notification-audio"></audio>
      )}
      <Dropdown
        isOpen={menuOpen}
        toggle={() => {
          setMenuOpen(!menuOpen);
        }}
        className="d-inline-block"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-notification-3-line"></i>
          <span
            className="noti-dot"
            id="my-notification-dot"
            style={{ display: 'none' }}
          ></span>
        </DropdownToggle>
        <DropdownMenu
          right
          className="dropdown-menu-lg p-0"
          aria-labelledby="page-header-notifications-dropdown"
          style={{ minWidth: '350px' }}
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">
                  Notifications
                  {!notification_state.fetching_history &&
                    notification_state.unread_count > 0 && (
                      <Badge pill className="badge-soft-danger ml-2">
                        {notification_state.unread_count}
                      </Badge>
                    )}
                </h6>
              </Col>
            </Row>
          </div>
          {menuOpen && (
            <InfiniteScroll
              dataLength={notification_state.notification_data.length}
              next={() => {
                fetchNextNotifications(notification_state.current_page + 1);
              }}
              hasMore={notification_state.has_more}
              loader={
                <div className="text-center py-5">
                  <Loader
                    type="TailSpin"
                    color="#d3932c"
                    height={60}
                    width={60}
                  />
                </div>
              }
              height={350}
              endMessage={
                <div className="text-center py-3">
                  <hr className="my-4" />
                  <p className="lead">No more Notifications right now</p>
                </div>
              }
            >
              {notification_state.notification_data.map((item) => (
                <Link
                  to={item.notification_data.openURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-reset notification-item"
                  key={item._id}
                >
                  <Media>
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i
                          className={iconMapper(item.notification_data.tag)}
                        ></i>
                      </span>
                    </div>
                    <Media body>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="mt-0 mb-1">
                          {item.notification_data.title}
                        </h6>
                        <div
                          className="my-remove-icon-div"
                          data-tip={'Remove'}
                          onClick={removeNotification(item._id)}
                        >
                          <i className="ri-delete-bin-7-line"></i>
                        </div>
                        <ReactTooltip
                          type="dark"
                          effect="solid"
                          place="right"
                        />
                      </div>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{item.notification_data.content}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{' '}
                          {moment(new Date(item.notification_time)).fromNow()}
                        </p>
                      </div>
                    </Media>
                  </Media>
                </Link>
              ))}
            </InfiniteScroll>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    notification_state: state.notification_state,
    web_notification_sound_mute_status: state.Layout.socket_sound_mute_status
  };
};

export default connect(mapStateToProps, {
  fetchNextNotifications,
  removeNotificationFromHistory,
  removeRedDotFromBellIcon
})(NotificationDropdown);

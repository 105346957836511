import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import NotFound from './pages/NotFound';
import './theme.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import {
  loadUser,
} from './store/actions';

const App = ({
  loadUser,
}) => {


  useEffect(() => {
    loadUser();
  }, []);

  return (
    <React.Fragment>
      <Toaster />
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              exact
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              exact
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
          <AppRoute
            layout={NonAuthLayout}
            component={NotFound}
            isAuthProtected={false}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    myLoginState: state.Login
  };
};

export default connect(mapStateToProps, {
  loadUser,
})(App);

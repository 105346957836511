import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import GetRequestComponent from '../../../components/Common/GetRequest';
const GetAllContacts = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Contact', link: '#' },
    { title: 'Get All Contact', link: '#' }
  ]);
  const handleSendRequest = async () => {
    try {
      const response = await axios.post(url, JSON.parse(requestBody));
      setResponse(response.data);
    } catch (error) {
      setResponse(error.message);
    }
  };
  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const queryparams=[
    {
      title:'page',
      type:'text',
    },
    {
      title:'limit',
      type:'text',
    }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Get All Contacts"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Get all Contacts of the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <GetRequestComponent title="Get All Contacts" headers={headers} queryparams={queryparams} url='/v2/tp/contacts'/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default GetAllContacts;

import {
	FETCH_INITIAL_HISTORY,
	INITIAL_HISTORY_LOAD_SUCCESSFUL,
	FETCH_NEXT_NOTIFICATION_PAGE,
	REMOVE_NOTIFICATION_FROM_HISTORY,
	REMOVE_RED_DOT_BELL_ICON,
	GOT_NEW_NOTIFICATION_HISTORY,
} from './actionTypes';

const INIT_STATE = {
	notification_data: [],
	current_page: 1,
	current_limit: 10,
	fetching_history: true,
	fetching_next_page: false,
	unread_count: 0,
	has_more: true,
	red_dot_shown_once: false,
};

const NotificationHistory = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_INITIAL_HISTORY:
			return {
				...state,
				fetching_history: true,
			};
		case INITIAL_HISTORY_LOAD_SUCCESSFUL:
			return {
				...state,
				notification_data: [...state.notification_data, ...action.payload.results],
				current_page: action.payload?.metadata.page,
				has_more: action.payload?.metadata.page < action.payload?.metadata.total_pages,
				current_limit: action.payload?.metadata.limit,
				unread_count: action.payload?.metadata.unread_count,
				fetching_history: false,
				red_dot_shown_once: action.payload?.metadata.unread_count > 0,
			};
		case REMOVE_RED_DOT_BELL_ICON:
			return {
				...state,
				red_dot_shown_once: false,
			};
		case GOT_NEW_NOTIFICATION_HISTORY:
			return {
				...state,
				notification_data: [action.payload, ...state.notification_data],
				red_dot_shown_once: true,
			};
		case FETCH_NEXT_NOTIFICATION_PAGE:
			return {
				...state,
				fetching_next_page: true,
			};
		case REMOVE_NOTIFICATION_FROM_HISTORY:
			return {
				...state,
				notification_data: state.notification_data.filter(
					(k) => k._id !== action.payload.notification_id
				),
			};
		default:
			return state;
	}
};

export default NotificationHistory;

import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import PostRequestComponent from '../../../components/Common/PostRequest';
const AddNewMembership = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Membership', link: '#' },
    { title: 'Add New Membership', link: '#' }
  ]);
  const handleSendRequest = async () => {
    try {
      const response = await axios.post(url, JSON.parse(requestBody));
      setResponse(response.data);
    } catch (error) {
      setResponse(error.message);
    }
  };
  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const body=[
    {
      title:'membership_name',
      type:'text',
      required:true
    },
    {
      title:'membership_code',
      type:'text',
      required:true
    },
    {
      title:'payment_method',
      required:true,
      type:'dropdown',
      options:[{label:'Cash',value:'Cash'},{label:'Google Pay',value:'Google Pay'},{label:'Phone Pe',value:'Phone Pe'},{label:'UPI',value:'UPI'}]
    },
    // {
    //   title:'payment_amount',
    //   type:'text',
    //   required:true
    // },
    {
      title:'payment_note',
      type:'text'
    },
    {
      title:'payment_receipt',
      type:'file'
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Add New Membership"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Add a New Membership for the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <PostRequestComponent title="Add New Membership" headers={headers} body={body} url='/v2/tp/membership' type='form'/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default AddNewMembership;

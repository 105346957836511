import {
	CHECK_LOGIN,
	LOGIN_USER_SUCCESSFUL,
	API_ERROR,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	LOAD_USER_SUCCESSFUL,
} from './actionTypes';

const initialState = {
	loginError: '',
	message: null,
	loading: true,
	isAuthenticated: false,
	user: null,
	user_details: null,
	city_details: null,
	staging_apikey:null,
	production_apikey:null
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case CHECK_LOGIN:
			state = {
				...state,
				loading: true,
			};
			break;

		case LOGIN_USER_SUCCESSFUL:
			state = {
				...state,
				loading: false,
				isAuthenticated: true,
				user_details: action.payload.user_details,
				city_details: action.payload.city_details,
			};
			break;

		case LOAD_USER_SUCCESSFUL:
			state = {
				...state,
				loading: false,
				user_details: action.payload.user_details,
				city_details: action.payload.city_details,
				isAuthenticated: true,
			};
			break;

		case LOGOUT_USER:
			state = { ...initialState };
			break;

		case LOGOUT_USER_SUCCESS:
			state = { ...initialState };
			break;

		case API_ERROR:
			state = {
				...state,
				loading: false,
				loginError: action.payload,
				isAuthenticated: false,
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default login;

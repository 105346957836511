import axios from 'axios';
const instance = axios.create({
  baseURL: process.env.REACT_APP_NODE_ENV==='production'?process.env.REACT_APP_BACKEND_BASE_URL:process.env.REACT_APP_BACKEND_STAGING_URL,
  timeout: 1000000
});

const setAuthToken = () => {
  const access_token = localStorage.getItem('ACCESS_TOKEN');
  if (access_token)
    instance.defaults.headers.common[
      'authorization'
    ] = `Bearer ${access_token}`;
  else delete instance.defaults.headers.common['authorization'];
};

export default instance;
export { setAuthToken };

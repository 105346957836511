import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import GetRequestComponent from '../../../components/Common/GetRequest';
const GetAContactDetail = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Contact', link: '#' },
    { title: 'Get Contact Details', link: '#' }
  ]);

  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const urlparams=[{
    title:'contact_id',
    type:'text',
    required:true
  }]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Get A Contact Detail"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Get details of a Particular Contact of the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <GetRequestComponent title="Get A Contact Detail" headers={headers} urlparams={urlparams} url='/v2/tp/contacts/:contact_id'/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default GetAContactDetail;

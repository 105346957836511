import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER, LOAD_USER } from './actionTypes';
import { apiError, loginUserSuccessful, loadUserSuccessful } from './actions';

// AUTH related methods
import { loginRequest, getAuthenticatedUser } from '../../helpers/auth-requests';

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
	try {
		const data = yield call(loginRequest, '/v2/tp/auth/login', {
			phone_number: user.phone_number,
			password: user.password,
		});

		localStorage.setItem('ACCESS_TOKEN', data.responses.access_token);
		localStorage.setItem('user_details', JSON.stringify(data.responses.user_details));
		localStorage.setItem('staging_apikey', JSON.stringify(data.responses.staging_apikey));
		localStorage.setItem('production_apikey', JSON.stringify(data.responses.production_apikey));
		localStorage.setItem('city_details', JSON.stringify(data.responses.city_details));

		yield put(loginUserSuccessful(data.responses));
		history.push('/dashboard');
		window.location.reload();
	} catch (e) {
		yield put(apiError(e.error));
	}
}

function* loadUser() {
	try {
		const user_details = JSON.parse(localStorage.getItem('user_details'));
		const city_details = JSON.parse(localStorage.getItem('city_details'));
		if (user_details && city_details) {
			const response_data = yield call(
				getAuthenticatedUser,
				`/v2/tp/auth/verify`
			);
			if (response_data) {
				localStorage.setItem('user_details', JSON.stringify(response_data.user_details));
				localStorage.setItem('city_details', JSON.stringify(response_data.city_details));
				localStorage.setItem('staging_apikey', JSON.stringify(response_data.staging_apikey));
				localStorage.setItem('production_apikey', JSON.stringify(response_data.production_apikey));
				yield put(loadUserSuccessful(response_data));
			}
		} else {
			yield put(apiError(''));
		}
	} catch (e) {
		localStorage.removeItem('ACCESS_TOKEN');
		localStorage.removeItem('user_details');
		localStorage.removeItem('city_details');
		yield put(apiError('Session Expired. Kindly login again'));
	}
}

function* logoutUser({ payload: { history } }) {
	try {
		localStorage.removeItem('ACCESS_TOKEN');
		localStorage.removeItem('user_details');
		localStorage.removeItem('city_details');
		history.push('/login');
	} catch (error) {
		yield put(apiError(error));
	}
}

export function* watchUserLogin() {
	yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchLoggedInUser() {
	yield takeEvery(LOAD_USER, loadUser);
}

export function* watchUserLogout() {
	yield takeEvery(LOGOUT_USER, logoutUser);
}

function* loginSaga() {
	yield all([fork(watchUserLogin), fork(watchUserLogout), fork(watchLoggedInUser)]);
}

export default loginSaga;

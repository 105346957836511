import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import NotificationDropdown from '../CommonForBoth/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/ProfileMenu';
import {
  changeNotificationPermission,
  changeSoundMuteState
} from '../../store/actions';
import logosmdark from '../../assets/images/logo-sm-dark.png';
import logodark from '../../assets/images/logo-dark.png';
import logosmlight from '../../assets/images/logo-sm-light.png';
import logolight from '../../assets/images/logo-light.png';
import _ from 'lodash';
import { showOrderAcceptNotification } from '../../helpers';

const Header = ({
  toggleMenuCallback,
  myLoginState,
  socketMuteStatus,
  changeSoundMuteState
}) => {
  const toggleFullScreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmdark} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodark} alt="" height="20" />
                </span>
              </Link>

              <Link
                to="/dashboard"
                className="logo logo-light d-flex justify-content-center align-items-center"
              >
                <span className="logo-sm">
                  <img src={logosmlight} alt="" height="22" />
                </span>
                <span className="logo-lg" style={{ width: '100px' }}>
                  <img
                    src={logolight}
                    alt=""
                    style={{ height: '50px', width: '90px' }}
                  />
                </span>
              </Link>
            </div>

            <Button
              size="sm"
              color="none"
              type="button"
              onClick={toggleMenuCallback}
              className="px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="ri-menu-2-line align-middle"></i>
            </Button>
          </div>

          <div className="d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <ReactTooltip type="dark" effect="solid" multiline={true} />

              {/* <h5
                className="mr-4 border-right border-dark pr-3 mb-0"
                style={{ cursor: 'pointer' }}
                data-tip={`Assigned City: ${_.capitalize(
                  myLoginState?.city_details?.city_name
                )}
										<br/> City Code: C${myLoginState?.city_details?.city_seq_code}
										<br/> State: ${_.capitalize(myLoginState?.city_details?.state_name)}
										`}
              >
                {_.capitalize(myLoginState?.city_details?.city_name)}
              </h5> */}
              {/* <p
                className="mr-4 border-dark pr-3 mb-0"
                style={{ cursor: 'pointer',fontWeight:"bold",color:"green" }}
              >
                Staging:  {myLoginState?.user_details?.staging_apikey}
                <br/>Production: {myLoginState?.user_details?.production_apikey}
              </p> */}
              {/* <h3
                className="mr-4 text-primary mb-0"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  changeSoundMuteState(!socketMuteStatus);
                }}
              >
                {socketMuteStatus ? (
                  <i className="ri-volume-mute-fill align-middle"></i>
                ) : (
                  <i className="ri-volume-up-fill align-middle"></i>
                )}
              </h3> */}

              {/* <Button
                component={Link}
                href='/orders/create'
                target="_blank"
                rel="noopener noreferrer"
                color={
                  myLoginState?.city_details?.order_accept?.all_order &&
                  myLoginState?.city_details?.order_accept?.schedule_order
                    ? 'success'
                    : 'danger'
                }
                type="button"
                size="sm"
                className="waves-effect waves-light mr-1 btn-rounded font-size-13 px-3"
                data-tip={showOrderAcceptNotification(
                  myLoginState?.city_details?.order_accept
                )}
              >
                Add New Task
              </Button> */}
{/* 
              <Button
                component={Link}
                href='/orders/contacts/create'
                target="_blank"
                rel="noopener noreferrer"
                color={
                  myLoginState?.city_details?.order_accept?.all_order &&
                  myLoginState?.city_details?.order_accept?.schedule_order
                    ? 'info'
                    : 'danger'
                }
                type="button"
                size="sm"
                className="waves-effect waves-light mr-1 btn-rounded font-size-13 px-3"
                // data-tip={showOrderAcceptNotification(
                //   myLoginState?.city_details?.order_accept
                // )}
              >
                Task b/w contacts
              </Button> */}
            </div>

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <Button
                color="none"
                type="button"
                className="header-item noti-icon waves-effect"
                onClick={toggleFullScreen}
              >
                <i className="ri-fullscreen-line"></i>
              </Button>
            </div>

            {/* {!myLoginState.loading &&
              myLoginState?.user_details?.admin_role === 'admin' && (
                <div className="dropdown d-inline-block">
                  <Link to="/settings">
                    <Button
                      color="none"
                      type="button"
                      className="header-item noti-icon right-bar-toggle waves-effect"
                    >
                      <i className="ri-settings-2-line"></i>
                    </Button>
                  </Link>
                </div>
              )} */}

            {/* <NotificationDropdown /> */}

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    notificationEnabled: notificationEnabledState,
    socket_sound_mute_status: socketMuteStatus
  } = state.Layout;
  const myLoginState = state.Login;
  return { notificationEnabledState, myLoginState, socketMuteStatus };
};

export default connect(mapStateToProps, {
  changeNotificationPermission,
  changeSoundMuteState
})(Header);

import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import PostRequestComponent from '../../../components/Common/PostRequest';
const CreateNewOrder = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Order', link: '#' },
    { title: 'Create New Order', link: '#' }
  ]);
  const handleSendRequest = async () => {
    try {
      const response = await axios.post(url, JSON.parse(requestBody));
      setResponse(response.data);
    } catch (error) {
      setResponse(error.message);
    }
  };
  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const body=[
    {
      title:'vehicle_type',
      type:'dropdown',
      options:[{label:'scooter',value:'scooter'},{label:'bike',value:'bike'},{label:'scooter_with_care',value:'scooter_with_care'}]
    },
    {
      title:'today_date',
      type:'text',
      required:true,
      placeholder:'YYYY-MM-DD'
    },
    {
      title:'order_amount',
      type:'text',
      required:true
    },
    {
      title:'order_description',
      type:'text',
      required:true
    },
    {
      title:'client_pick_up_code',
      type:'text',
      placeholder:'9999'
    },
    {
      title:'pick_up_contact',
      type:'text',
      required:true,
      placeholder:'_id of the contact'
    },
    {
      title:'drop_off_contact',
      type:'text',
      required:true,
      placeholder:'_id of the contact'
    },
    {
      title:'scheduled_time',
      type:'datetime-local'
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Create New Order"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Create a New Order for the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <PostRequestComponent title="Create New Order" headers={headers} body={body} url='/v2/tp/order' type='form'/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNewOrder;

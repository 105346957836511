import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import PostRequestComponent from '../../../components/Common/PostRequest';
const AddNewContact = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Contact', link: '#' },
    { title: 'Add New Contact', link: '#' }
  ]);
  const handleSendRequest = async () => {
    try {
      const response = await axios.post(url, JSON.parse(requestBody));
      setResponse(response.data);
    } catch (error) {
      setResponse(error.message);
    }
  };
  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const body=[
    {
      title:'name',
      type:'text',
      required:true
    },
    {
      title:'phone_number',
      type:'text',
      required:true
    },
    {
      title:'addr_line_1',
      type:'text',
      required:true
    },
    {
      title:'addr_line_2',
      type:'text',
      required:true
    }
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Add New Contact"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Create a New Contact for the Shop. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <PostRequestComponent title="Add New Contact" headers={headers} body={body} url='/v2/tp/contacts' type="data"/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default AddNewContact;

import React, { useState } from 'react';
import axios from 'axios';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
} from 'reactstrap';
import PutRequestComponent from '../../../components/Common/PutRequest';
const UpdateContact = () => {
  const [url, setUrl] = useState('');
  const [requestBody, setRequestBody] = useState('');
  const [response, setResponse] = useState(null);
  const [breadcrumbItems] = useState([
    { title: 'Contact', link: '#' },
    { title: 'Update Contact Details', link: '#' }
  ]);
  const headers=[
    {
      title:'apikey',
      type:'text',
      required:true,
    }
  ]
  const body=[
    {
      title:'name',
      type:'text',
      required:true
    },
    {
      title:'phone_number',
      type:'text',
      required:true
    },
    {
      title:'addr_line_1',
      type:'text',
      required:true
    },
    {
      title:'addr_line_2',
      type:'text',
      required:true
    },
  ]
  const urlparams=[{
    title:'contact_id',
    type:'text',
    required:true,
  }]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs
            title="Update A Contact"
            breadcrumbItems={breadcrumbItems}
          />
          <p>To Update Contact Deatils for a Shop Contact. Fields marked with <span style={{color:"red"}}>*</span> are required</p>
      <PutRequestComponent title="Update A Contact" headers={headers} body={body} urlparams={urlparams} type="body" url='/v2/tp/contacts/:contact_id'/>
      </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateContact;

import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Alert
} from 'reactstrap';
import ProfileIcon from '../../assets/images/profile-icon.png';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import SweetAlert from 'react-bootstrap-sweetalert';

const ProfileMenu = ({ auth_user }) => {
  const tp_id = JSON.parse(localStorage.getItem('user_details'))?.user_id;

  const [menuOpen, setMenuOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState({
    newPassword: false,
    oldPassword: false
  });
  const [userInput, setUserInput] = useState({
    new_password: '',
    old_password: ''
  });
  const [responseAlert, setResponseAlert] = useState({
    success: false,
    error: false,
    message: '',
    showAlert: false,
    title: ''
  });

  async function changePasswordRequest(event, values) {
    setChangePasswordLoading(true);
    try {
      setAuthToken();
      const { data } = await sendRequest.post(
        `/v2/tp/auth/${tp_id}/change_password`,
        {
          old_password: values.old_password,
          new_password: values.new_password
        }
      );
      closeChangePasswordModal();
      setResponseAlert({
        showAlert: true,
        message: data.message,
        success: true,
        error: false,
        title: 'Updated'
      });
    } catch (e) {
      setResponseAlert({
        showAlert: false,
        message: e.response?.data?.error,
        success: false,
        error: true,
        title: 'Error'
      });
    }
    setChangePasswordLoading(false);
  }

  function changeInput(e) {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value
    });
  }

  function closeChangePasswordModal() {
    if (!changePasswordLoading) {
      setUserInput({
        new_password: '',
        old_password: ''
      });
      setChangePasswordModalOpen(false);
    }
    if (responseAlert.error && !responseAlert.showAlert) {
      resetResponseAlert();
    }
  }

  const validateOldAndNewPassword = async (value, ctx, input, cb) => {
    if (value === userInput.old_password) {
      return cb('Old and New password should not be same');
    }
    return cb(true);
  };

  function resetResponseAlert() {
    setResponseAlert({
      success: false,
      error: false,
      message: '',
      showAlert: false,
      title: ''
    });
  }

  return (
    <React.Fragment>
      {responseAlert.showAlert && (
        <SweetAlert
          error={responseAlert.error}
          success={responseAlert.success}
          title={responseAlert.title}
          onConfirm={() => {
            if (responseAlert.success) {
              window.location.reload();
            }
          }}
        >
          {responseAlert.message}
        </SweetAlert>
      )}
      <Modal isOpen={changePasswordModalOpen} backdrop="static">
        <ModalHeader toggle={closeChangePasswordModal}>
          Change Password
        </ModalHeader>
        <ModalBody>
          {responseAlert?.error ? (
            <Alert
              color="danger"
              role="alert"
              isOpen={responseAlert?.error}
              toggle={resetResponseAlert}
            >
              <i className="mdi mdi-block-helper mr-2"></i>
              {responseAlert?.message}
            </Alert>
          ) : null}
          <AvForm
            className="needs-validation"
            onValidSubmit={changePasswordRequest}
          >
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    Old Password{' '}
                    <i
                      className={
                        togglePassword.oldPassword
                          ? 'ri-eye-line ml-2'
                          : 'ri-eye-off-line ml-2'
                      }
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTogglePassword({
                          ...togglePassword,
                          oldPassword: !togglePassword.oldPassword
                        });
                      }}
                    ></i>
                  </Label>
                  <AvField
                    name="old_password"
                    type={togglePassword.oldPassword ? 'text' : 'password'}
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Old Password is required'
                      }
                    }}
                    disabled={changePasswordLoading}
                    value={userInput.old_password}
                    onChange={changeInput}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>
                    New Password{' '}
                    <i
                      className={
                        togglePassword.newPassword
                          ? 'ri-eye-line ml-2'
                          : 'ri-eye-off-line ml-2'
                      }
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setTogglePassword({
                          ...togglePassword,
                          newPassword: !togglePassword.newPassword
                        });
                      }}
                    ></i>
                  </Label>
                  <AvField
                    name="new_password"
                    type={togglePassword.newPassword ? 'text' : 'password'}
                    className="form-control"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'New Password is required'
                      },
                      minLength: {
                        value: 6,
                        errorMessage: 'Password must be at least 6 characters'
                      },
                      async: validateOldAndNewPassword
                    }}
                    disabled={changePasswordLoading}
                    value={userInput.new_password}
                    onChange={changeInput}
                  />
                </FormGroup>
              </Col>
            </Row>

            <ModalFooter>
              <Button
                type="button"
                color="light"
                size="sm"
                onClick={closeChangePasswordModal}
                disabled={changePasswordLoading}
              >
                Close
              </Button>
              <Button
                type="submit"
                size="sm"
                color="success"
                disabled={changePasswordLoading}
              >
                {changePasswordLoading ? 'Loading ...' : 'Submit'}
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </Modal>
      <Dropdown
        isOpen={menuOpen}
        toggle={() => {
          setMenuOpen(!menuOpen);
        }}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user mr-1 border border-dark"
            src={ProfileIcon}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ml-1 text-transform">
            {auth_user?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <i className="ri-phone-fill align-middle mr-2"></i>{' '}
            {auth_user?.phone_number}
          </DropdownItem>
          <hr className="my-1" />
          <DropdownItem
            className="text-danger"
            onClick={() => {
              setChangePasswordModalOpen(true);
            }}
          >
            <i className="ri-lock-password-line align-middle mr-1 text-danger"></i>{' '}
            Change Password
          </DropdownItem>
          <DropdownItem className="text-danger" href="/logout">
            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{' '}
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_user: state.Login.user_details
  };
};

export default connect(mapStateToProps)(ProfileMenu);
